jQuery(document).ready(function($){
	if ($('body').hasClass('page-id-48')) {
		var partNumberColumn = '.column-2';
		var partNameColum = '.column-1';
	}else{
		var partNumberColumn = '.column-1';
		var partNameColum = '.column-2';
	}
	
	$('.quoteBox').click(function(){
		
		
		$(this).toggleClass('checked');
		updateParts();
	})

	$(document).on('click','span.close',function(){
		var part = $(this).parent().prop('class');
		part = part.replace('part ','');
		part = part.toString();
		
		$(partNumberColumn+':contains("' + part + '")').siblings('.quote').children().removeClass('checked');
		updateParts();
	})

function updateParts(){
	var parts = [];
	$('#field_1_13 textarea').html('');
		$('.partsHtml').html('');

	$('.quoteBox.checked').each(function(){
		var loopPartNumber = $(this).parent().parent().find(partNumberColumn).html();
		var loopPartName = $(this).parent().parent().find(partNameColum).html();

		parts.push({name: loopPartName,number: loopPartNumber});
	})
	console.log(parts);
	$(parts).each(function(){
		$('#field_1_13 textarea').append('Part Number: ' + this.number + '\nPart Name: ' + this.name + '\n\n'  );
		$('.partsHtml').append('<span class="part ' + this.number + '">' + this.number + ' <span class="close">X</span></span>');
	})


}
})