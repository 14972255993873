jQuery(function($){
  $window = $(window);
    // flexslider
    $(window).load(function() {


      $('.flexslider').flexslider({
        animation: "slide",
        controlNav: false,
        directionNav: false,
        animationSpeed: 1000,
        start: function (slidestart){

          $('.flex-active-slide h3, .flex-active-slide h5').addClass('fadeEffect');
          $('.flex-active-slide a').addClass('fadeEffect');

        },

        before: function(slideeffectremove){

          $('.flex-active-slide h3, .flex-active-slide h5').removeClass('fadeEffect');
          $('.flex-active-slide .btn').removeClass('fadeEffect');

        },

        after: function(slideeffect){

          $('.flex-active-slide h3, .flex-active-slide h5').addClass('fadeEffect');
          $('.flex-active-slide .btn').addClass('fadeEffect');

        }



      });




    });

//header 


$(window).scroll(function() { 
  if ($window.scrollTop() > 100) {
    $('body').addClass('masthead-fixed');
  } else{
    $('body').removeClass('masthead-fixed');
  } 



});

// header close 

$('#announcement .close').click(function(){
  $('#announcement').fadeOut('fast');
});

// search 
$('#search').click(function(){
  $('#searchbox').toggleClass('show');
});

//closechat 
$('.close_chat').click(function(){
  $('.needhelp').fadeOut();
});

//append footer signup and social
$(document).ready(function(){

 $('#wysiwyg_widgets_widget-4').append($('#append'));
}); 

// hero fade effect 
$(document).ready(function(){
 $('.hero h3, .hero h5, .hero .btn').addClass('fadeEffect');
});

// smooth scroll
$(document).ready(function(){


  $(function() {
      $('a.btn[href*=\\#]').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var $target = $(this.hash);
          $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
          if ($target.length) {
            var targetOffset = $target.offset().top;
            $('html,body').animate({
              scrollTop: targetOffset - 100
            }, 500);
            return false;
          }
        }
      });
    });


});

// responsive menu
$(document).ready(function(){
  $('.sliding-panel-button,.sliding-panel-fade-screen,.sliding-panel-close').on('click touchstart',function (e) {
    $('.sliding-panel-content,.sliding-panel-fade-screen').toggleClass('is-visible');
    e.preventDefault();
  });
});

// team member

$('.teamMember h5').click(function(){
  $(this).toggleClass('active');
});

$('.teamMember img').click(function(){
  $(this).siblings('h5').toggleClass('active');
});

// tabs
$( function() {
    $( "#tabs" ).tabs();
  } );

// same height

if ($(window).width() > 1024) {
  var contentheight = $('.threecol').height() - 114;
  $('.threecol .box article .textwrap').css('minHeight', contentheight + 'px');
}

});



